import React from 'react'

import { Helmet } from 'react-helmet'

import './home.css'

import pfp from '../playground_assets/pfp.jpg'
import sketch from '../playground_assets/sketch.png'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Linden Kwok's Portfolio</title>
        <meta
          name="description"
          content="A collection of work projects and side projects for Linden Kwok, Software Developer."
        />
        <meta property="og:title" content="Linden Kwok's Portfolio" />
        <meta
          property="og:description"
          content="A collection of work projects and side projects for Linden Kwok, Software Developer."
        />
      </Helmet>
      <div className="home-hero">
        <div className="home-container01">
          <img
            alt="image"
            src={pfp}
            className="home-image"
          />
          <h1 className="home-text">Linden Kwok</h1>
          <span className="home-text01">
            Software Developer | Vancouver, BC
          </span>
          <div className="home-social-bar">
            <svg viewBox="0 0 1024 1024" className="home-icon">
              <path d="M512.008 12.642c-282.738 0-512.008 229.218-512.008 511.998 0 226.214 146.704 418.132 350.136 485.836 25.586 4.738 34.992-11.11 34.992-24.632 0-12.204-0.48-52.542-0.696-95.324-142.448 30.976-172.504-60.41-172.504-60.41-23.282-59.176-56.848-74.916-56.848-74.916-46.452-31.778 3.51-31.124 3.51-31.124 51.4 3.61 78.476 52.766 78.476 52.766 45.672 78.27 119.776 55.64 149.004 42.558 4.588-33.086 17.852-55.68 32.506-68.464-113.73-12.942-233.276-56.85-233.276-253.032 0-55.898 20.004-101.574 52.76-137.428-5.316-12.9-22.854-64.972 4.952-135.5 0 0 43.006-13.752 140.84 52.49 40.836-11.348 84.636-17.036 128.154-17.234 43.502 0.198 87.336 5.886 128.256 17.234 97.734-66.244 140.656-52.49 140.656-52.49 27.872 70.528 10.35 122.6 5.036 135.5 32.82 35.856 52.694 81.532 52.694 137.428 0 196.654-119.778 239.95-233.79 252.624 18.364 15.89 34.724 47.046 34.724 94.812 0 68.508-0.596 123.644-0.596 140.508 0 13.628 9.222 29.594 35.172 24.566 203.322-67.776 349.842-259.626 349.842-485.768 0-282.78-229.234-511.998-511.992-511.998z"></path>
            </svg>
            <svg viewBox="0 0 877.7142857142857 1024" className="home-icon02">
              <path d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
            </svg>
          </div>
        </div>
        <svg viewBox="0 0 1024 1024" className="home-icon04">
          <path d="M316 366l196 196 196-196 60 60-256 256-256-256z"></path>
        </svg>
      </div>
      <div className="home-hero1">
        <div className="home-container02">
          <h1 className="home-text02">Work Experience</h1>
          <span className="home-text03">
            <span>
              Hello, I&apos;m Linden. I graduated from Simon Fraser University
              in 2020 with a Bachelor&apos;s Degree in Computer Science and
              completed an Associate’s Degree in Computer Science from Langara
              in 2017.
            </span>
            <br></br>
            <span></span>
            <br></br>
            <span>
              I am a solution-oriented software developer with interests in data
              security, algorithm efficiency, and full stack web development.
              Self-motivated and team focused work attitude.
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span></span>
            <span></span>
          </span>
          <div className="home-btn-group">
            <button className="home-button button">Resume</button>
          </div>
        </div>
        <img
          alt="image"
          src={sketch}
          className="home-image1"
        />
      </div>
      <div className="home-gallery">
        <div className="home-container03">
          <div className="home-container04">
            <h1 className="home-text09">Tetris Fruits</h1>
            <span className="home-text10">
              A C++ based game combining Tetris and Falling Fruits
            </span>
          </div>
        </div>
        <div className="home-container05">
          <div className="home-container06">
            <h1 className="home-text11">
              <span>
                Mayflower Group
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </h1>
            <span className="home-text13">
              <span>Commissioned Website for a Strata Cleaning Company</span>
            </span>
          </div>
        </div>
        <div className="home-container07">
          <div className="home-container08">
            <h1 className="home-text15">Word Clock</h1>
            <span className="home-text16">
              Minimalistic Clock Built With React
            </span>
          </div>
        </div>
        <div className="home-container09">
          <div className="home-container10">
            <h1 className="home-text17">Raytracer</h1>
            <span className="home-text18">
              <span>A C++ based raytracer from scratch</span>
            </span>
          </div>
        </div>
        <div className="home-container11">
          <div className="home-container12">
            <h1 className="home-text20">Born Chinese</h1>
            <span className="home-text21">
              <span>Commissioned Website for Nonprofit Group</span>
            </span>
          </div>
        </div>
        <div className="home-container13">
          <div className="home-container14">
            <h1 className="home-text23">Simplistic Virtualized CPU</h1>
            <span className="home-text24">
              A C++ based CPU running custom assembly code
            </span>
          </div>
        </div>
      </div>
      <footer className="home-footer">
        <span className="home-text25">
          © 2022 Linden Kwok, All Rights Reserved.
        </span>
        <div className="home-container15">
          <div className="home-icon-group">
            <svg viewBox="0 0 877.7142857142857 1024" className="home-icon06">
              <path d="M438.857 73.143c242.286 0 438.857 196.571 438.857 438.857 0 193.714-125.714 358.286-300 416.571-22.286 4-30.286-9.714-30.286-21.143 0-14.286 0.571-61.714 0.571-120.571 0-41.143-13.714-67.429-29.714-81.143 97.714-10.857 200.571-48 200.571-216.571 0-48-17.143-86.857-45.143-117.714 4.571-11.429 19.429-56-4.571-116.571-36.571-11.429-120.571 45.143-120.571 45.143-34.857-9.714-72.571-14.857-109.714-14.857s-74.857 5.143-109.714 14.857c0 0-84-56.571-120.571-45.143-24 60.571-9.143 105.143-4.571 116.571-28 30.857-45.143 69.714-45.143 117.714 0 168 102.286 205.714 200 216.571-12.571 11.429-24 30.857-28 58.857-25.143 11.429-89.143 30.857-127.429-36.571-24-41.714-67.429-45.143-67.429-45.143-42.857-0.571-2.857 26.857-2.857 26.857 28.571 13.143 48.571 64 48.571 64 25.714 78.286 148 52 148 52 0 36.571 0.571 70.857 0.571 81.714 0 11.429-8 25.143-30.286 21.143-174.286-58.286-300-222.857-300-416.571 0-242.286 196.571-438.857 438.857-438.857zM166.286 703.429c1.143-2.286-0.571-5.143-4-6.857-3.429-1.143-6.286-0.571-7.429 1.143-1.143 2.286 0.571 5.143 4 6.857 2.857 1.714 6.286 1.143 7.429-1.143zM184 722.857c2.286-1.714 1.714-5.714-1.143-9.143-2.857-2.857-6.857-4-9.143-1.714-2.286 1.714-1.714 5.714 1.143 9.143 2.857 2.857 6.857 4 9.143 1.714zM201.143 748.571c2.857-2.286 2.857-6.857 0-10.857-2.286-4-6.857-5.714-9.714-3.429-2.857 1.714-2.857 6.286 0 10.286s7.429 5.714 9.714 4zM225.143 772.571c2.286-2.286 1.143-7.429-2.286-10.857-4-4-9.143-4.571-11.429-1.714-2.857 2.286-1.714 7.429 2.286 10.857 4 4 9.143 4.571 11.429 1.714zM257.714 786.857c1.143-3.429-2.286-7.429-7.429-9.143-4.571-1.143-9.714 0.571-10.857 4s2.286 7.429 7.429 8.571c4.571 1.714 9.714 0 10.857-3.429zM293.714 789.714c0-4-4.571-6.857-9.714-6.286-5.143 0-9.143 2.857-9.143 6.286 0 4 4 6.857 9.714 6.286 5.143 0 9.143-2.857 9.143-6.286zM326.857 784c-0.571-3.429-5.143-5.714-10.286-5.143-5.143 1.143-8.571 4.571-8 8.571 0.571 3.429 5.143 5.714 10.286 4.571s8.571-4.571 8-8z"></path>
            </svg>
            <a
              href="https://www.linkedin.com/in/lindenstkwok/"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link"
            >
              <svg viewBox="0 0 877.7142857142857 1024" className="home-icon08">
                <path d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"></path>
              </svg>
            </a>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Home
